import React from "react";
import styles from "../pages/About.module.css";

const About = () => {
  return (
    <div>
      <div className={`${styles.aboutContainer}`}>
        <h1 className={`${styles.aboutHeading}`}>Hi, I'm Prachi.</h1>
        <img
          src="./images/About-me-1.jpg"
          alt="about-image"
          className={`${styles.aboutImage}`}
        />
        <p className={`${styles.aboutText}`}>
          Hi there! I'm Prachi—a front-end developer and graphic designer with a
          passion for creating beautiful designs that bring your ideas to life
          and capture your favorite moments.
          <br />
          I love keeping things simple and
          elegant, creating designs that put a smile on your face. Whether it's
          for a special event or a personal project, I'm here to make something
          unique just for you.
          <br />
          I also create personalised designs on demand. You can just drop me a message, and I'll get back to you within 24 hours!
          <br />
          I design books as well and recently had the joy of working on a children's book called "De reis met je rugzakje."
          <br />
          Along with my wonderful husband, we run Lush Hues Design.
          <br />
          Making art brings me so much happiness, and I hope you enjoy it as much as I've enjoyed creating it. Feel free to browse our shop and reach out with any questions or comments—I'd love to hear from you!
        </p>
      </div>
    </div>
  );
};

export default About;
