import React from "react";
import { useLocation } from "react-router-dom";
import CategoryCard from "./CategoryCard";
import cardData from "../data/cardData.json";

const BirthdayInvitations = () => {
  const location = useLocation();
  const { filterCategory } = location.state || {};

    // If filterCategory is provided, filter based on it; otherwise, default to "Birthday Invitations"
    const birthdayCards = cardData.filter(card =>
      card.category === (filterCategory || "Birthday Invitations")
    );

  return (
    <div className="container mt-4">
      {/* <h2>Birthday Invitations</h2> */}
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {birthdayCards.map((card) => (
          <CategoryCard key={card.id} card={card} />
        ))}
      </div>
    </div>
  );
};

export default BirthdayInvitations;
