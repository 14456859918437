// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home.jsx";
import About from "./pages/About.jsx";
import Contact from "./pages/Contact.jsx";
import CardDetails from "./components/CardDetails.jsx";
import Footer from "./components/Footer.jsx";
import BirthdayInvitations from "./components/BirthdayInvitations.jsx";
import BabyShowerInvitations from "./components/BabyShowerInvitations.jsx";
import FavorTags from "./components/FavorTags.jsx";
// require('dotenv').config();


function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/card-details" element={<CardDetails />} />
          <Route path="/birthday-invitations" element={<BirthdayInvitations />} />
          <Route path="/baby-shower-invitations" element={<BabyShowerInvitations />} />
          <Route path="/favor-tags" element={<FavorTags/>} />
        </Routes>
      </div>

      <div>
        <hr />
        <Footer />
      </div>

    </Router>
  );
}

export default App;
