import React from "react";
import Cover from "../components/Cover";
import styles from "../pages/Home.module.css";
import CategoryCardRow from "../components/CategoryCardRow";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <div>
        <Cover />
      </div>
      <div
        className={`${styles.listingTitle}`}
        // style={{
        //   display: "flex",
        //   justifyContent: "space-between",
        //   alignItems: "center",
        //   width: "100%",
        //   position: "relative",
        // }}
      >
        <h4 className={`${styles.listingHeading}`}>Birthday Invitations</h4>

        <div
          className={`${styles.showAll}`}
          style={{
            marginLeft: "auto",
            paddingRight: "75px",
            paddingTop: "50px",
          }}
        >
          <Link
            className={`${styles.showAll}`}
            to="/birthday-invitations"
            state={{ filterCategory: "Birthday Invitations" }}
          >
            Show All
          </Link>
        </div>
      </div>
      <div>
        <CategoryCardRow filterCategory="Birthday Invitations" />
      </div>
      <div
        className={`${styles.listingTitle}`}
        // style={{
        //   display: "flex",
        //   justifyContent: "space-between",
        //   alignItems: "center",
        //   width: "100%",
        //   position: "relative",
        // }}
      >
        <h4 className={`${styles.listingHeading}`}>Baby Shower Invitations</h4>
        <div
          className={`${styles.showAll}`}
          style={{
            marginLeft: "auto",
            paddingRight: "75px",
            paddingTop: "50px",
          }}
        >
          <Link
            className={`${styles.showAll}`}
            to="/baby-shower-invitations"
            state={{ filterCategory: "Baby Shower Invitations" }}
          >
            Show All
          </Link>
        </div>
      </div>
      <div>
        <CategoryCardRow filterCategory="Baby Shower Invitations" />
      </div>
      <div
        className={`${styles.listingTitle}`}
      >
        <h4 className={`${styles.listingHeading}`}
        >
          Favor Tags
        </h4>
        <div
          className={`${styles.showAll}`}
          style={{
            marginLeft: "auto",
            paddingRight: "75px",
            paddingTop: "50px",
          }}
        >
          <Link className={`${styles.showAll}`}
          to="/favor-tags"
          state={{ filterCategory: "Favor Tags" }}>
            Show All
          </Link>
        </div>
      </div>
      <div>
        <CategoryCardRow filterCategory="Favor Tags" />
      </div>
    </div>
  );
};

export default Home;
