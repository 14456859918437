import React from "react";
import CategoryCard from "./CategoryCard";
import cardData from "../data/cardData.json";

const CategoryCardRow = ({ filterCategory }) => {
  // Function to shuffle an array
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  // Filter, shuffle, and then take the first three cards
  const filteredCards = cardData.filter((card) => card.category === filterCategory);
  const randomThreeCards = shuffleArray(filteredCards).slice(0, 3);

  return (
    <div className="container mt-4">
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {randomThreeCards.map((card, index) => (
          <CategoryCard key={index} card={card} />
        ))}
      </div>
    </div>
  );
};

export default CategoryCardRow;
