import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import styles from "./ContactForm.module.css";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [disabled, setDisabled] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    display: false,
    message: "",
    type: "",
  });

  // Shows alert message for form submission feedback
  const toggleAlert = (message, type) => {
    setAlertInfo({ display: true, message, type });

    // Hide alert after 5 seconds
    setTimeout(() => {
      setAlertInfo({ display: false, message: "", type: "" });
    }, 5000);
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destructure data object
    const { name, email, message } = data;
    try {
      // Disable form while processing submission
      setDisabled(true);

      // Define template params
      const templateParams = {
        name,
        email,
        message,
      };

      // Use emailjs to email contact form data
      await emailjs.send(
        "service_f0gwr4v",
        "template_deyj8do",
        templateParams,
        "2AJzcpUiORiDs07k8"
      );

      // Display success alert
      toggleAlert('Form submission was successful!', 'success');
    } catch (e) {
      console.error(e);
      // Display error alert
      toggleAlert('Uh oh. Something went wrong.', 'danger');
    } finally {
      // Re-enable form submission
      setDisabled(false);
      // Reset contact form fields after submission
      reset();
    }
  };

  return (
    <div className="ContactForm">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className={`${styles.contactUs}`}>
              <h1>Contact Us</h1>
              <form
                id={`${styles.contactForm}`}
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                {/* Row 1 of form */}
                <div className={`row ${styles.formRow}`}>
                  <div className="col-6">
                    <input
                      type="text"
                      name="name"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Please enter your name",
                        },
                        maxLength: {
                          value: 30,
                          message: "Please use 30 characters or less",
                        },
                      })}
                      className={`form-control ${styles.formInput}`}
                      placeholder="Name"

                    ></input>
                    {errors.name && (
                      <span className={`${styles.errorMessage}`}>
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  <div className="col-6">
                    <input
                      type="email"
                      name="email"
                      {...register("email", {
                        required: true,
                        // pattern:
                        //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                      className={`form-control ${styles.formInput}`}
                      placeholder="Email"
                    ></input>
                    {errors.email && (
                      <span className={`${styles.errorMessage}`}>
                        Please enter a valid email address
                      </span>
                    )}
                  </div>
                </div>
                {/* Row 2 of form */}
                <div className={`row ${styles.formRow}`}>
                  <div className="col">
                    <textarea
                      rows={5}
                      name="message"
                      {...register("message", {
                        required: true,
                      })}
                      className={`form-control ${styles.formInput}`}
                      placeholder="Message"
                    ></textarea>
                    {errors.message && (
                      <span className={`${styles.errorMessage}`}>
                        Please enter a message
                      </span>
                    )}
                  </div>
                </div>
                <button className={`${styles.submitBtn}`} type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

// import React from "react";
// import { useForm } from "react-hook-form";

// const TestForm = () => {
//   const { register, handleSubmit } = useForm();

//   const onSubmit = (data) => {
//     console.log(data);
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <input name="test" {...register("test", { required: true })} />
//       <button type="submit">Submit</button>
//     </form>
//   );
// };

// export default ContactForm;
